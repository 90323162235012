@use "/src/config" as *;

.loginModal {
  width: calc(100% - 40px);
  max-width: 421px;
  background: $login-bg;
  padding: 2em;

  .loginAction,
  .doubleAction {
    padding: 0;
    width: 100%;
    display: flex;
    box-sizing: content-box;
    z-index: 3;
    position: revert;
    align-items: center;

    & > svg {
      padding: 10px;
    }
  }
  .loginAction {
    justify-content: flex-end;
    z-index: 3;
  }

  .doubleAction {
    justify-content: space-between;

    svg:not(:first-child) {
      padding: 10px 0 10px;
    }
  }
  .loginContent {
    .loginButton,
    button {
      span {
        padding: 12px 0;
      }
    }
  }
  .loginContent:not(.fullscreen) {
    padding: 0;
  }
}

.titleContainer {
  width: 100%;
}

.titleContainer h1 {
  color: $primary-text;
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 24px;
}

.titleContainer h2 {
  margin-bottom: 0.5em;
  font-size: 24px;
  font-weight: 700;
}

.titleContainer {
  .loginSubtitle {
    margin-bottom: 0.5em;
    font-weight: 300;
  }
}
.actionContainer {
  text-align: center;
}
.actionContainer > * {
  margin: 1rem 0;
  width: 100%;
  padding: 0;
}

.scrim {
  border-radius: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
  will-change: opacity;
  opacity: 0.46;
  background-color: $modal-scrim;
  border-color: $modal-scrim;
  z-index: 100;
}

.actionContainer > a {
  text-decoration: underline $secondary-shades-500;
  text-underline-offset: 5px;
}

.subtitle {
  display: block;
}

.codeError {
  p.codeErrorText span {
    color: $error-text;

    a.codeResend {
      margin: 0;
      text-decoration: underline;
      text-underline-offset: 2px;
      color: $secondary-shades-100;
      cursor: pointer;
    }
  }
}
.tosContainer {
  flex-direction: column;
  & .tos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin: 5px 0;
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
      margin: 0 0 0 10px;
    }
  }
}

@include breakpointMax(sm) {
  .loginModal {
    width: 100%;
    .titleContainer {
      .loginSubtitle {
        font-size: 14px;
      }
    }

    .actionContainer {
      width: inherit;
    }
  }
}
